<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add Project</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="filteredList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-link
            :class="{ 'selected-project': selectedProjectId == data.item.id }"
            @click="goToEditor(data.item)"
          >
            <div
              style="
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 15vw;
                height: 100%;
                display: flex;
                align-items: center;
              "
              :class="{
                'dark-color': $store.state.appConfig.layout.skin === 'dark',
              }"
            >
              {{ data.item.name }}
            </div>
          </b-link>
        </template>

        <!-- Column: License Type -->
        <template #cell(license)="data">
          <b-link @click="$router.push('/payment')">
            <div
              style="
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 45px;
                height: 100%;
                display: flex;
                align-items: center;
              "
              class="license"
              :class="{
                'dark-color': $store.state.appConfig.layout.skin === 'dark'
              }"
            >
              {{ licenseTypes[data.item.project_license_type] }}
              <LicenseExpired :project="data.item" :bottomPush="5" :rightPush="-5" />
            </div>
          </b-link>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <b-link
            @click="goToEditor(data.item)"
          >
            <div
              style="
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 6vw;
                height: 100%;
                display: flex;
                align-items: center;
              "
              :class="{
                'dark-color': $store.state.appConfig.layout.skin === 'dark',
              }"
            >
              {{ roles(data.item.role) }}
            </div>
          </b-link>
        </template>

        <!-- Column: UUID -->
        <template #cell(uuid)="data">
          <b-link @click="goToEditor(data.item)">
            <div
              style="
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 17vw;
                height: 100%;
                display: flex;
                align-items: center;
              "
              :class="{
                'dark-color': $store.state.appConfig.layout.skin === 'dark',
              }"
            >
              {{ data.item.uuid }}
            </div>
          </b-link>
        </template>

        <!-- Column: Comment -->
        <!-- <template #cell(comment)="data">
          <b-link @click="goToEditor(data.item)">
            <div
              style="
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 10vw;
              "
              :class="{
                'dark-color': $store.state.appConfig.layout.skin === 'dark',
              }"
            >
              {{ data.item.comment }}
            </div>
          </b-link>
        </template> -->

        <!-- Column:  Create date -->
        <template #cell(create_date)="data">
          <div
            style="
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 5vw;
            "
            :class="{
              'dark-color': $store.state.appConfig.layout.skin === 'dark',
            }"
          >
            {{ formatDate(data.item.create_date) }}
          </div>
        </template>

        <!-- Column:  Update date -->
        <template #cell(update_date)="data">
          <div
            style="
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 5vw;
            "
            :class="{
              'dark-color': $store.state.appConfig.layout.skin === 'dark',
            }"
          >
            {{ formatDate(data.item.update_date) }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'apps-users-edit',
                params: { projectId: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showModal(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-1"
      size="sm"
      centered
      ok-title="Remove"
      ok-variant="danger"
      title="Are you sure?"
      @ok="sendRequestToRemoveProject"
    >
      <div class="d-block">Do you want to remove this project?</div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BDropdown,
  VBModal,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import useUsersList from "./useUsersList";
import userStoreModule from "../userStoreModule";
import UserListAddNew from "./UserListAddNew.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { errorMessage } from "@/auth/utils";
import LicenseExpired from "@/views/components/UI/LicenseExpired.vue";

export default {
  components: {
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    vSelect,
    LicenseExpired,
},
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    selectedId: null,
    selectedProjectIDLocal: null,
    licenseTypes: {
      1: "Basic",
      2: "Plus",
      3: "Pro",
    },
  }),
  computed: {
    listProjects() {
      return this.$store.state.Projects.projects;
    },
    filteredList() {
      return this.listProjects.filter((project) =>
        project.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        project.uuid.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    selectedProjectId() {
      return this.selectedProjectIDLocal ? this.selectedProjectIDLocal : store.state.Project.project.id
    },
  },
  methods: {
    formatDate(date) {
      let formattedDate = new Date(date).toLocaleDateString()
      let splittedDate = formattedDate.split('/')
      return (splittedDate[0] + '-' + splittedDate[1] + '-' + splittedDate[2])
    },
    async goToEditor(project) {
      this.selectedProjectIDLocal = project.id
      store.commit("Project/SET_MY_PROJECT", project);
      if (project.project_type != "external") {
        await store
          .dispatch("Editor/getProjectEditors", project.id)
          .then((response) => {
            let selectedDevelopmentEditor = response.data.find(
              (x) => x.development === true
            );
            this.$store.commit("Editor/SET_EDITOR", selectedDevelopmentEditor)
            this.$router.push({
              name: "apps-editor-edit",
              params: {
                projectId: project.id,
                editorId: selectedDevelopmentEditor.id,
              },
            });
          })
          .catch((err) => {
            errorMessage(this.$toast, err);
          });
      }
    },
    showModal(id) {
      this.selectedId = id;
      this.$root.$emit("bv::show::modal", "modal-1", "#btnShow");
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-1", "#btnShow");
    },
    toggleModal() {
      this.$root.$emit("bv::toggle::modal", "modal-1", "#btnToggle");
    },
    sendRequestToRemoveProject() {
      store
        .dispatch("Project/deleteProject", { projectId: this.selectedId })
        .then(() => {
          this.selectedId = null;
          this.hideModal();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "You have successfully remove this project",
              icon: "TrashIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          errorMessage(this.$toast, err);

          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Something went wrong",
          //     icon: "AlertTriangleIcon",
          //     variant: "danger",
          //     text: err.response.data?.detail
          //       ? err.response.data?.detail
          //       : "Please try again",
          //   },
          // });
        });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    store.dispatch("Projects/GET_MY_PROJECTS");
    store.dispatch("Templates/GET_ALL_TEMPLATES")

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      roles,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      roles,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dark-color {
  color: #b4b7bd;
}

.license {
  color: black;
  font-weight: bold;
  &.dark-color {
    color: #b4b7bd;
  }
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
td {
  position: relative !important;
}
.selected-project {
  border-top: 1px dashed #fd1c62 !important;
  border-bottom: 1px dashed #fd1c62 !important;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  box-shadow: 0 0 8px 0 rgb(34 41 47 / 20%);

  padding-left: 25px;
}
</style>
