<template>
  <div class="customizer d-none d-md-block" :class="{'open': isCustomizerOpen}">
    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
      <div>
        <h2 class="text-uppercase mb-0">
          {{relatedProjectName}}
        </h2>
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="isCustomizerOpen = false"
      />
    </div>
    <!-- Header -->
    <iframe v-if="developmentURL" allowfullscreen="true" :src="developmentURL + '?isHosting=true'"></iframe>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import useAppCustomizer from './useTemplateCustomizer'
import store from '@/store'


export default {
  props: {
    isCustomizerOpen: {
      type: Boolean,
      default: false,
    },
    templateID: {
      type: Number,
      required: true,
    },
  },
  components: {
    // BSV
    BLink, 
  },
  data() {
    return {
      developmentURL: null,
      relatedProjectName: null,
      isCustomizerClose: false,
    }
  },
  watch: {
    isCustomizerOpen(val) {
      if (val) {
        store.dispatch('Template/loadTemplate', this.templateID)
          .then((response) => {
            this.developmentURL = response.data.development_url
            this.relatedProjectName = response.data.related_project_name
          })
      }
    },
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,
    } = useAppCustomizer()

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

iframe {
  width: 100%;
  height: 600px;
  margin-top: 40px;
}

.customizer-section {
  padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
    margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px)
}
.customizer .customizer-toggle {
    background: #fd1b62;
}
</style>
